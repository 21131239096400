body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-width: 900px;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html {
  width: 100%;
  min-width: 900px;
  height: 100%;
}


/* 设置滚动条宽度和颜色 */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

/* 设置滚动条的 thumb 部分样式 */
::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0, 0, 0, 0.1);
}

/* 设置滚动条的 hover 时 thumb 部分样式 */
::-webkit-scrollbar-thumb:hover {
  background-color: rgba(0, 0, 0, 0.4);
}

/* 隐藏滚动条背景和箭头 */
::-webkit-scrollbar-track,
::-webkit-scrollbar-button {
  display: none;
}

