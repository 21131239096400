.custom-template-container {
  &--mobile {
    .t-date-picker__panel-content {
      flex-direction: column;
      align-items: center;
      .t-date-picker__panel-time {
        border: none;
        padding: 0;
        .t-time-picker__panel-body {
          height: 100px;
          .t-time-picker__panel-body-active-mask {
            top: 35%;
          }
        }
      }
    }
  }
}
