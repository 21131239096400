.case-scroll-inner {
	position: relative;
  display: flex;
  width: 100%;
  height: 100%;
	user-select: none;
  .landing-page-case-item {
		position: relative;
    width: 500px;
    height: 198px;
    margin-right: 40px;
    background: linear-gradient(360deg, #ffffff 0%, #f3f5f8 100%);
    border-radius: 8px;
		.case-logo {
			position: absolute;
			width: 56px;
			height: 46px;
			top: 12px;
			left: 80px;
		}
		.landing-page-case-item-logo {
			position: absolute;
			left: 32px;
			top: 40px;
		}
		.landing-page-case-item-title {
			position: absolute;
			top: 40px;
			left: 92px;
			height: 26px;
			font-family: 'PingFang SC';
			font-style: normal;
			font-weight: 500;
			font-size: 16px;
			line-height: 26px;
			/* identical to box height, or 162% */

			text-align: justify;

			color: #16181A;
		}
		.landing-page-case-item-sub-title {
			position: absolute;
			top: 74px;
			left: 92px;
			height: 26px;
			font-family: 'PingFang SC';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			/* or 171% */

			text-align: justify;

			color: #293957;
		}
		.landing-page-case-item-des {
			position: absolute;
			top: 104px;
			left: 92px;
			height: 26px;
			width: 376px;
			font-family: 'PingFang SC';
			font-style: normal;
			font-weight: 400;
			font-size: 14px;
			line-height: 24px;
			/* or 171% */
			text-align: justify;
			color: #293957;
		}
  }
	.left-scroll {
		position: absolute;
		top: 240px;
		left: 320px;
		cursor: pointer;
		&:hover {
			transform: scale(1.5);
			path {
				fill: #0052D9;
			}
		}
	}
	.right-scroll {
		position: absolute;
		top: 240px;
		left: 270px;
		cursor: pointer;
		&:hover {
			transform: scale(1.5);
			path {
				fill: #0052D9;
			}
		}
	}
}
