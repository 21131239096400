.rightContent {
  display: flex;
  text-align: center;
  align-items: center;
  .license-button {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    padding: 0 10px;
    margin-right: 30px;
    border: 1px solid #CFD5DE;
    border-radius: 40px;
    line-height: 30px;
    color: #000;
    line-height: 36px;
    cursor: pointer;
    .license-button-icon {
      color: #000;
      font-size: 16px;
    }
  }
  .license-button-active {
    border: 1px solid #0052D9;
  }
  .userAvatar {
    width: 36px;
    height: 36px;
    margin-right: 10px;
    border-radius: 100%;
    cursor: pointer;
  }
}

.rightContentUserDropdownItem {
  max-width: unset !important;
  min-width: 80px !important;
  ww-open-data {
    line-height: 30px;
  }
  .companyName {
    font-size: 12px;
    color: #0052D9;
  }
  .copyButton {
    text-align: center;
    height: 30px;
    line-height: 30px;
  }
}


