.App {
  height: 100%;
  width: 100%;
}

body > .main-root {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.main-routers-container {
  overflow-y: auto;
  flex: 1;
}

.micro-frontend-container {
  height: 100%;
  width: 100%;
}

.menu-and-content {
  display: flex;
  height: calc(100% - 60px);
}

/* @overwrite */
.t-drawer__mask {
  /* 企微浏览器中，过度存在兼容性闪烁 */
  transition: none !important;
}
