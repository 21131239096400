.content {
  width: 100%;
  height: 100%;
  min-height: 766px;
  background-color: #fff;
  text-align: center;
  .img {
    img {
      margin-top: 15%;
      width: 80px;
    }
  }
  .text {
    font-family: 'PingFang SC';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.4);
    .button {
      margin-left: 5px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      padding: 0;
      color: #006eff;
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }
    .name {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      padding: 0;
      color: #006eff;
      cursor: pointer;
    }
  }
}
