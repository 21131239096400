.top-bar {
  display: flex;
  align-items: center;
  height: 60px;
  width: 100%;
  border-radius: 0px;
  box-shadow: inset 0px -1px 0px #e7eaef;
  .andon-logo {
    margin-left: 20px;
  }
  .menus {
    margin-left: 100px;
    :global {
      .t-head-menu {
        height: 100%;
        .t-head-menu__inner {
          height: 100%;
        }
      }
      .t-menu__item.t-menu__item--plain {
        .t-menu__content {
          padding: 0;
          width: 100%;
          .active {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */
            color: #006eff;
          }
        }
      }
      .t-menu__content a {
        display: inline-block;
        width: 100%;
        margin: 0;
        padding: 10px 0;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        color: #000000;

        mix-blend-mode: normal;
        opacity: 0.9;
      }
      .t-submenu {
        .t-menu__item.t-is-active {
          span {
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 600 !important;
            font-size: 16px;
            line-height: 20px;
            color: #006eff !important;
          }
        }
        .t-menu__popup-wrapper {
          .t-menu__item {
            padding: 0;
            .t-menu__content {
              padding: 0;
              .active {
                font-family: 'PingFang SC';
                font-style: normal;
                font-weight: 600 !important;
                font-size: 16px;
                line-height: 20px;
                color: #006eff !important;
              }
            }
            .t-menu__content a {
              display: inline-block;
              width: 100%;
              margin: 0;
              padding: 10px 0px;
            }
          }
        }
        .t-menu__item {
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height, or 125% */

          color: #000000;

          mix-blend-mode: normal;
          opacity: 0.9;
        }
      }
    }
  }
  .right {
    margin-right: 16px;
    flex: 1;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}
