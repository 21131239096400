@keyframes typing {
  from {
    width: 0;
  }
}
.loadingBody {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 10;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  background-color: #fff;
  .animateText {
    font-size: 22px;
    font-weight: 900;
    width: 154px;
    white-space: nowrap;
    overflow: hidden;
    animation: typing 2s steps(6);
    margin: 20px 0;
  }
  .text {
    font-size: 14px;
  }
}
