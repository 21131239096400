.dialogBody {
  text-align: center;
  margin-top: -52px;

  .inline-option {
    &-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 12px;
    }

    opacity: 0.6;
    border: 1px solid grey;
    border-radius: 10px;
    padding: 10px 20px;
    cursor: pointer;

    &.active {
      opacity: 1;
      border-color: blue;
      color: blue;
    }
  }

  .option {
    padding-left: 20px;
    text-align: left;
    height: 50px;
    line-height: 50px;
    border: 1px solid grey;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    opacity: 0.6;
    img {
      position: relative;
      top: 2px;
    }
  }
  .activeOption {
    text-align: left;
    padding-left: 20px;
    height: 50px;
    line-height: 50px;
    border: 1px solid blue;
    border-radius: 10px;
    margin-bottom: 20px;
    cursor: pointer;
    color: blue;
    img {
      position: relative;
      top: 2px;
      filter: drop-shadow(100px 0 0 #0152D9);
      transform: translateX(-100px);
    }
  }
  .welcomeTitle {
    font-size: 20px;;
    font-weight: 800;
  }
  .optionTitle {
    font-size: 16px;
    font-weight: 500;
  }
  .icon {
    position: relative;
    top: 3px;
  }
  .andonLogo {
    margin-bottom: -40px;
  }
}
.errorIcon {
  color: blue;
  position: relative;
  top: 5px;
}
