.landing-page-container {
  $landing-page-process-title-top: 30px;
  $landing-page-process-height: 510px;
  $landing-page-process-rectangle-top: 127px;
  width: 100%;
  .landing-page-header {
    position: relative;
    height: 260px;
    width: 100%;
    background: url('~@/assets/png/banner.png');
    background-size: 100% 100%;
    background-size: cover;
    background-position-y: 55%;
    background-repeat: no-repeat;
    .landing-page-header-center {
      position: relative;
      height: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .dialog {
        .tips {
          font-size: 12px;
        }
      }
      .landing-page-header-title {
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 42px;
        line-height: 52px;
        /* identical to box height, or 124% */

        color: #16181a;
      }
      .landing-page-header-des {
        position: absolute;
        width: 687px;
        height: 112px;
        left: 0px;
        top: 20px;

        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        /* or 175% */

        color: #495770;
        text-align: left;
      }
      .ask-button {
        position: absolute;
        width: 136px;
        height: 44px;
        top: 285px;
        left: 152px;
        background: #ffffff;
        box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 44px;
        color: #000;
        text-align: center;
        cursor: pointer;
        border-radius: 0;
        border: none;
      }
      .experience-button {
        position: absolute;
        width: 136px;
        height: 44px;
        top: 190px;
        left: 0px;
        background: #0052d9;
        box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 44px;
        color: #ffffff;
        text-align: center;
        cursor: pointer;
        border-radius: 0;
        border: none;
      }
    }
  }
  .landing-page-process {
    position: relative;
    height: $landing-page-process-height;
    width: 100%;
    background-color: #fff;
    .landing-page-process-center {
      position: relative;
      height: 100%;
      max-width: 1200px;
      margin: 0 auto;
      .landing-page-process-title {
        position: absolute;
        height: 36px;
        width: 448px;
        left: 459px;
        top: $landing-page-process-title-top;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        /* identical to box height, or 129% */
        text-align: left;
        color: #181818;
      }
      .landing-page-process-sub-title {
        position: absolute;
        height: 22px;
        width: 430px;
        left: 468px;
        top: $landing-page-process-title-top + 48;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */

        text-align: center;

        color: #495770;
      }
      .landing-page-process-rectangle {
        box-sizing: border-box;
        position: absolute;
        top: $landing-page-process-rectangle-top;
        left: 0px;
        width: 590px;
        height: 348px;
        background: linear-gradient(360deg, #ffffff 0%, #f3f5f8 100%);
        border: 2px solid #ffffff;
        box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff;
        border-radius: 8px;
      }
      .landing-page-process-png {
        position: absolute;
        top: $landing-page-process-rectangle-top + 12;
        left: 0px;
        width: 573px;
        height: 328px;
      }
      .customer-pull {
        position: absolute;
        top: $landing-page-process-rectangle-top;
        left: 650px;
      }
      .customer-pull-title {
        display: block;
        position: absolute;
        height: 26px;
        top: $landing-page-process-rectangle-top;
        left: 690px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #16181a;
      }
      .customer-pull-sub-title {
        display: block;
        position: absolute;
        height: 88px;
        width: 426px;
        top: $landing-page-process-rectangle-top + 40;
        left: 690px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #495770;
        text-align: left;
      }
    }
    .bad-case {
      position: absolute;
      top: $landing-page-process-rectangle-top + 110;
      left: 650px;
    }
    .bad-case-title {
      display: block;
      position: absolute;
      height: 26px;
      top: $landing-page-process-rectangle-top + 110;
      left: 690px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #16181a;
    }
    .bad-case-sub-title {
      display: block;
      position: absolute;
      height: 88px;
      width: 426px;
      top: $landing-page-process-rectangle-top + 150;
      left: 690px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #495770;
      text-align: left;
    }
    .lean {
      position: absolute;
      top: $landing-page-process-rectangle-top + 240;
      left: 650px;
    }
    .lean-title {
      display: block;
      position: absolute;
      height: 26px;
      top: $landing-page-process-rectangle-top + 240;
      left: 690px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 26px;
      color: #16181a;
    }
    .lean-sub-title {
      display: block;
      position: absolute;
      height: 88px;
      width: 426px;
      top: $landing-page-process-rectangle-top + 280;
      left: 690px;
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #495770;
      text-align: left;
    }
  }
  .landing-page-tabs {
    position: relative;
    width: 100%;
    height: 520px;
    background: #f5f7fa;
    .landing-page-tabs-center {
      position: relative;
      height: 100%;
      max-width: 1220px;
      margin: 0 auto;
      .landing-page-tabs-title {
        position: absolute;
        height: 36px;
        width: 560px;
        left: 428px;
        top: 20px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 36px;
        /* identical to box height, or 129% */
        text-align: center;
        color: #181818;
        white-space: nowrap;
      }
      .landing-page-tabs-sub-title {
        position: absolute;
        height: 22px;
        left: 407px;
        top: 128px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        /* identical to box height, or 157% */
        text-align: center;
        color: #495770;
      }
      .landing-page-tabs-inner {
        position: absolute;
        top: 70px;
        left: 0;
        right: 0;
        bottom: 0;
        margin: 0 auto;
        width: 1220px;
        height: 420px;
      }
      .wrapper {
        display: flex;
        &>a {
          text-decoration: none;
          display: flex;
        }
      }
      .feature {
        display: inline-block;
        width: 400px;
        padding: 20px;
        box-sizing: border-box;
        display: flex;
        &:hover {
          box-shadow:  0px 1px 10px rgba(0, 0, 0, 0.15);
          cursor: pointer;
          :global {
            .link {
              display: inline-block;
            }
          }
        }
        &-left {
          width: 48px;
          margin-right: 20px;
          img {
            width: 100%;
          }
        }
        &-right {
          flex: 1;
        }
        &-title {
          margin-bottom: 8px;
          font-weight: 400;
          font-size: 18px;
          color: #000;
          line-height: 28px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          align-items: center;
        }
        &-content {
          font-size: 14px;
          color: #495770;
          line-height: 22px;
        }
        &-link {
          background-color: #0D55CF;
          margin-left: 10px;
          color: #fff;
          font-size: 12px;
          padding: 0px 5px;
          height: 20px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          display: inline-block;
          line-height: 20px;
          display: none;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .landing-page-case {
    display: flex;
    height: 568px;
    width: 100%;
    .landing-page-case-img {
      width: 45%;
      height: 100%;
      background: url('~@/assets/png/case.png');
      background-size: 100% 100%;
    }
    .landing-page-case-scroll {
      position: relative;
      height: 100%;
      width: 55%;
      background: #242933;
      text-align: left;
      .landing-page-case-scroll-title {
        display: block;
        height: 48px;
        width: 300;
        margin: 80px 0 0 40px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 48px;
        /* identical to box height, or 171% */
        color: #ffffff;
      }
      .landing-page-case-scroll-des {
        width: 400px;
        height: 20px;
        margin: 136px 0 0 40px;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        color: #ffffff;
      }
      .landing-page-case-scroll-content {
        position: absolute;
        top: 216px;
        left: -240px;
        height: 260px;
        width: calc(100% + 240px);
        overflow: hidden;
        .landing-page-case-scroll-cards {
          height: 100%;
          width: 1620px;
        }
      }
    }
  }
  .landing-page-case-mini {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 146px;
    background-color: #fff;
    .tencent-cloud-mini-card {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 200px;
      height: 80px;
      margin-right: 12px;
      background: #ffffff;
      border: 2px solid #ffffff;
      box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff,
        inset 0px 4px 20px rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      .tencent-cloud-mini-logo {
        width: 138px;
        height: 32px;
      }
    }
    .work-wx-mini-card {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 200px;
      height: 80px;
      margin-right: 12px;
      background: #ffffff;
      border: 2px solid #ffffff;
      box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff,
        inset 0px 4px 20px rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      .work-wx-mini-logo {
        width: 138px;
        height: 32px;
      }
    }
    .tencent-conference-mini-card {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 200px;
      height: 80px;
      margin-right: 12px;
      background: #ffffff;
      border: 2px solid #ffffff;
      box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff,
        inset 0px 4px 20px rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      .tencent-conference-mini-logo {
        width: 148px;
        height: 24px;
      }
    }
    .tapd-mini-card {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 200px;
      height: 80px;
      margin-right: 12px;
      background: #ffffff;
      border: 2px solid #ffffff;
      box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff,
        inset 0px 4px 20px rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      .tapd-mini-logo {
        width: 113px;
        height: 45px;
      }
    }
    .wx-pay-mini-card {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 200px;
      height: 80px;
      margin-right: 12px;
      background: #ffffff;
      border: 2px solid #ffffff;
      box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff,
        inset 0px 4px 20px rgba(255, 255, 255, 0.5);
      border-radius: 4px;
      .wx-pay-mini-logo {
        width: 164px;
        height: 55px;
      }
    }
  }
  .landing-page-footer {
    position: relative;
    height: 160px;
    width: 100%;
    background-color: #f5f7fa;
    .landing-page-footer-inner {
      position: relative;
      width: 1200px;
      height: 100%;
      margin: 0 auto;
      .landing-page-footer-inner-code-bg {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        left: 0;
        top: 25px;
        width: 110px;
        height: 110px;
        background: linear-gradient(360deg, #ffffff 0%, #f3f5f8 100%);
        border: 2px solid #ffffff;
        box-shadow: 8px 8px 20px rgba(55, 99, 170, 0.1), -8px -8px 20px #ffffff;
        border-radius: 4px;
        .landing-page-footer-inner-code {
          width: 97.78px;
          height: 97.78px;
        }
      }
    }
    .landing-page-footer-des {
      position: absolute;
      height: 64px;
      width: 400px;
      left: 140px;
      right: 51.1%;
      top: 48px;

      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 32px;
      /* or 160% */

      color: #000000;
      text-align: left;
    }
  }
}
